<template>
  <div class="artwork">
    <Card
      :config="item"
      :isFavorite="isFavorite(item.id)"
      @addFavorite="addFavorite"
      @removeFavorite="removeFavorite"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Card from '@/components/Card.vue'
import { baseImgCdn, rand, pad, getUrl, uuidv4 } from '../util.js'

export default {
  name: 'artwork',
  components: {
    Card
  },
  metaInfo() {
    return {
      title: this.title,
      link: [{ rel: 'image_src', href: this.item.url }],
      meta: [
        {
          property: 'og:image',
          content: this.item.url
        },
        {
          itemprop: 'thumbnailUrl',
          content: this.item.url
        },
        {
          itemprop: 'image',
          content: this.item.url
        }
      ]
    }
  },
  computed: {
    item() {
      return {
        id: this.$route.params.id,
        url: `${baseImgCdn}/${this.$route.params.artist}/seed_${this.$route.params.id}.jpg`
      }
    }
  },
  methods: {
    isFavorite(id) {
      return !!this.$store.state.favorites[id]
    },
    addFavorite(e) {
      this.$store.dispatch('addFavorite', e)
    },
    removeFavorite(e) {
      this.$store.dispatch('removeFavorite', e)
    }
  }
}
</script>

<style scoped></style>
